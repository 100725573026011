var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { withScoutTheme } from "../../../../ThemeContext/ThemeContext";
import { Snackbar } from "@material-ui/core";
import { Step1, Step2, Step3 } from "./Steps";
import useStyles from "../../../../IndeedIntegration/AdEditor/useStyles";
import ExitConfirmationDialog from "../../../../../components/SeekAd/AdEditor/Components/ExitConfirmationDialog";
import StepperComponent from "../../CustomUIElements/StepperComponent";
import StepperLeftButton from "../../Buttons/StepperLeftButton";
import StepperRightButton from "../../Buttons/StepperRightButton";
import JobAdSuccessDialog from "../../CustomUIElements/JobAdSuccessDialog";
import { handleNext } from "../handleNext";
import { handleDraftValue } from "../handleDraftValue";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import _ from "lodash";
const steps = [
  { label: "Classify Job", value: "job_details" },
  { label: "Ad Description", value: "job_description" },
  { label: "Preview Ad", value: "preview" }
];
function AdEditor({ jobAdParams }) {
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState(updateAnswers(jobAdParams));
  const [activeError, setActiveError] = useState(null);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [editExistingJob, setEditExistingJob] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const classes = useStyles();
  function updateAnswers(params) {
    return {
      featured: params.featured || false,
      title: params.title || "",
      description: handleDraftValue(params.job_description || "") || "",
      classification: params.classification || "",
      workType: params.work_type || "",
      workLocation: params.work_location || "",
      address: params.address || "",
      payType: params.pay_type || "",
      payRangeLow: params.pay_range_low || 0,
      payRangeHigh: params.pay_range_high || 0,
      screeningQuestions: params.screening_questions || [],
      benefits: params.benefits ? JSON.parse(params.benefits) : [],
      jobPreview: JSON.parse(params.job_preview) || {},
      externalUrl: params.external_url || "",
      externalId: params.external_id || ""
    };
  }
  function submit(type) {
    return __async(this, null, function* () {
      try {
        type === "draft" ? setSavingDraft(true) : setSaving(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        const { data } = yield axios.put(`/admin/job_ads/${jobAdParams.id}/view_jobs_ads/builder`, {
          headers: { "Content-Type": "application/json" },
          authenticity_token: token,
          job_ad: __spreadProps(__spreadValues(__spreadValues({
            step: activeStep,
            title: answers.title || "",
            job_description: draftToHtml(convertToRaw(answers.description.getCurrentContent())) || null,
            classification: answers.classification || null,
            work_type: answers.workType || null,
            work_location: answers.workLocation || null,
            address: answers.address || null,
            pay_type: answers.payType || null,
            pay_range_low: answers.payRangeLow || null,
            pay_range_high: answers.payRangeHigh || null,
            screening_questions: answers.screeningQuestions || [],
            benefits: answers.benefits || []
          }, activeStep === 1 && { featured: answers.featured || false }), activeStep === 1 && answers.featured && { job_preview: answers.jobPreview }), {
            external_url: answers.externalUrl || null,
            external_id: answers.externalId || null
          })
        });
        if (type === "draft") {
          handleSnack("Draft saved");
          setIsExitModalOpen(false);
          window.history.back();
        } else if (type === "submit") {
          setIsSuccessDialogOpen(true);
        } else if (type === "next") {
          if (activeStep === 1) {
            setPreviewUrl(data.preview_url);
          } else {
            const newAnswers = updateAnswers(data.job_ad);
            if (!_.isEqual(newAnswers, answers)) {
              setAnswers(newAnswers);
            }
          }
          setActiveStep(activeStep + 1);
        }
        console.log("submit", type);
      } catch (e) {
        if (type === "draft") {
          handleSnack("Unable to save draft");
        } else if (type === "submit") {
          handleSnack("Unable to post to Indeed. Please check required fields and try again.");
        } else if (type === "next") {
          setActiveStep(activeStep + 1);
        }
      } finally {
        setSaving(false);
        setSavingDraft(false);
      }
    });
  }
  function handleSnack(message) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }
  const requiredRefs = {
    title: useRef(null),
    address: useRef(null),
    classification: useRef(null),
    workType: useRef(null),
    workLocation: useRef(null),
    payType: useRef(null),
    salary: useRef(null),
    benefits: useRef(null),
    featured: useRef(null),
    jobPreview: useRef(null),
    description: useRef(null)
  };
  const handleNextButton = (type) => {
    handleNext(type, checkErrors, requiredRefs, setActiveError, submit);
  };
  function checkErrors() {
    const errorChecks = {
      title: !answers.title,
      address: !answers.address,
      classification: !answers.classification,
      workType: !answers.workType,
      workLocation: !answers.workLocation,
      payType: !answers.payType,
      salary: !answers.payRangeLow || !answers.payRangeHigh,
      description: answers.description.getCurrentContent().getPlainText("").length === 0
    };
    const error = Object.keys(errorChecks).find((ref) => errorChecks[ref]);
    return error;
  }
  useEffect(() => {
    setActiveError(null);
  }, [answers]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeStep]);
  return /* @__PURE__ */ React.createElement("div", { className: classes.containerOuter }, /* @__PURE__ */ React.createElement("div", { className: classes.container }, /* @__PURE__ */ React.createElement(StepperComponent, { activeStep, steps, classes }), activeStep === 0 && /* @__PURE__ */ React.createElement(
    Step1,
    {
      jobAdParams,
      answers,
      setAnswers,
      requiredRefs,
      activeError
    }
  ), activeStep === 1 && /* @__PURE__ */ React.createElement(
    Step2,
    {
      jobAdParams,
      answers,
      setAnswers,
      requiredRefs,
      activeError
    }
  ), activeStep === 2 && /* @__PURE__ */ React.createElement(
    Step3,
    {
      jobAdParams,
      answers,
      setAnswers,
      requiredRefs,
      activeError,
      previewUrl
    }
  ), /* @__PURE__ */ React.createElement("div", { className: classes.buttonContainer }, /* @__PURE__ */ React.createElement("a", { onClick: () => setIsExitModalOpen(true) }, "Save and exit"), /* @__PURE__ */ React.createElement("div", { className: classes.buttons }, /* @__PURE__ */ React.createElement(
    StepperLeftButton,
    {
      activeStep,
      onClick: () => setActiveStep(activeStep - 1),
      classes
    }
  ), /* @__PURE__ */ React.createElement(
    StepperRightButton,
    {
      activeStep,
      steps,
      isSaving: saving,
      onClick: () => handleNextButton(activeStep + 1 === steps.length ? "submit" : "next"),
      classes
    }
  )))), /* @__PURE__ */ React.createElement(
    ExitConfirmationDialog,
    {
      saving,
      savingDraft,
      setActiveStep,
      isExitModalOpen,
      setIsExitModalOpen,
      editExistingJob,
      submit
    }
  ), /* @__PURE__ */ React.createElement(
    JobAdSuccessDialog,
    {
      open: isSuccessDialogOpen,
      onClose: () => window.location.replace(document.referrer),
      answers,
      classes,
      adType: "viewjobs"
    }
  ), /* @__PURE__ */ React.createElement(Snackbar, { open: isSnackOpen, onClose: () => setIsSnackOpen(false), message: snackMessage }));
}
AdEditor.propTypes = {
  jobAdParams: PropTypes.object.isRequired
};
export default withScoutTheme(AdEditor);
