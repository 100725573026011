import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@material-ui/core";
import useStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
function Step3({ answers, setAnswers, jobAdParams, requiredRefs, activeError, previewUrl }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(Box, { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Preview job"), /* @__PURE__ */ React.createElement("a", { href: previewUrl, target: "_blank", rel: "noopener noreferrer" }, "View Job Preview"));
}
Step3.propTypes = jobAdPropTypes;
export default Step3;
