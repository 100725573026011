import React, { useState, useRef, Dispatch } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { validateEmail } from '../../../Candidates/EditCandidate';
import { scrollToElement } from '../../../utils/scroll-to-element';

export default function Fit2Work({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [accountEmail, setAccountEmail] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [fit2WorkPackage, setFit2WorkPackage] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);

  const fieldRefs = {
    accountEmail: useRef<HTMLInputElement>(null),
    candidateEmail: useRef<HTMLInputElement>(null),
    package: useRef<HTMLInputElement>(null),
    state: useRef<HTMLInputElement>(null)
  };

  const { data: fit2WorkData, isLoading: loadingFit2WorkData } = useQuery({
    queryKey: ['fit2work data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getFit2WorkData(application.id);
        return res;
      }
    },
    onSuccess: (res) => setAccountEmail(res.account_email),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting fit2work data, ${error}`,
          state: 'error'
        }
      })
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!validateEmail(accountEmail))
      inputErrors.accountEmail = 'Please enter a proper email address';
    if (!validateEmail(candidate.email))
      inputErrors.candidateEmail = 'Please enter a proper email address';
    if (!state) inputErrors.state = 'Please select a state';
    if (!fit2WorkPackage) inputErrors.package = 'Please select a package';
    setErrors(inputErrors);
    if (inputErrors.accountEmail) {
      scrollToElement(fieldRefs.accountEmail);
    } else if (inputErrors.candidateEmail) {
      scrollToElement(fieldRefs.candidateEmail);
    } else if (inputErrors.state) {
      scrollToElement(fieldRefs.state);
    } else if (inputErrors.package) {
      scrollToElement(fieldRefs.package);
    }
    return !Object.keys(inputErrors).length;
  };

  const { mutate: fit2WorkExport, isLoading: exportingFit2Work } = useMutation({
    mutationFn: async () => {
      if (application && candidate && state && fit2WorkPackage) {
        const { res } = await Api.fit2WorkExport(application.id, {
          candidate_email: candidate.email,
          account_email: accountEmail,
          state: state,
          package_ids: [fit2WorkData?.packages[fit2WorkPackage]]
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: error.error, state: 'error' } });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.FIT2WORK}
      label="Fit2Work export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Fit2Work request</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 3 }}>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Account email"
              value={accountEmail}
              required
              fullWidth
              onChange={(e) => setAccountEmail(e.target.value)}
              error={errors.accountEmail}
              innerRef={fieldRefs.accountEmail}
            />
            <FormTextField
              label="Candidate email"
              value={candidate.email}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
              error={errors.candidateEmail}
              innerRef={fieldRefs.candidateEmail}
            />
          </Stack>
          <Autocomplete
            disablePortal
            options={fit2WorkData?.states || []}
            value={state}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3 }}
            loading={loadingFit2WorkData}
            loadingText="Loading states..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                required
                helperText={errors.state}
                ref={fieldRefs.state}
              />
            )}
            onChange={(event, value) => setState(value)}
          />
          <Stack sx={styles.modalDoubleColumn}>
            <Autocomplete
              disablePortal
              options={Object.keys(fit2WorkData?.packages || {})}
              value={fit2WorkPackage}
              sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3 }}
              loading={loadingFit2WorkData}
              loadingText="Loading packages..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Packages"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                  required
                  helperText={errors.package}
                  ref={fieldRefs.package}
                />
              )}
              onChange={(event, value) => setFit2WorkPackage(value)}
            />
          </Stack>
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={() => validateInputs() && fit2WorkExport()}
          isLoading={exportingFit2Work}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
