import React, { Dispatch } from 'react';
import { Link, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from '../styles';
import { useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, IIntegrationsResponse } from '../../types';
import { ModalType } from '../../config';
import { theme } from '../../../../ThemeContext/ThemeObject';

export default function XrefRecord({
  updateStatusIcon,
  dispatch,
  formatDate
}: {
  updateStatusIcon: React.JSX.Element;
  dispatch: Dispatch<ApplicationAction>;
  formatDate: (date: string) => string;
}) {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  return (
    <Stack sx={styles.integrationDetails}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Xref requested checks</Stack>
        {updateStatusIcon}
      </Stack>
      <Table sx={styles.integrationDetailsTable}>
        <TableHead>
          <TableRow>
            {['Submitted at', 'Package name', 'Reference ID', 'Status', 'Referees'].map(
              (title, index) => (
                <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                  <Stack>{title}</Stack>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationsData?.xref_records.map((record, index) => (
            <TableRow key={index}>
              <TableCell sx={{ verticalAlign: 'top' }}>{formatDate(record.created_at)}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{record.package_name}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{record.reference_id}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{record.state}</TableCell>
              <TableCell>
                {record.referees?.length ? (
                  <Stack sx={{ rowGap: 2 }}>
                    {record.referees.map((referee, index) => (
                      <Stack key={index} sx={{ rowGap: 1 }}>
                        {referee.first_name} {referee.last_name} - {referee.state}
                        {referee.report_path && (
                          <Link
                            href={referee.report_path}
                            target="_blank"
                            style={{
                              color: 'inherit',
                              width: 'fit-content',
                              textDecoration: 'underline'
                            }}
                          >
                            View Report
                          </Link>
                        )}
                        {referee.unusual_activities && (
                          <Stack
                            sx={{
                              color: theme.palette.error.main,
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              width: 'fit-content'
                            }}
                            onClick={() => {
                              dispatch({
                                type: 'SET_MODALS_OPEN',
                                payload: ModalType.UNUSUAL_ACTIVITY
                              });
                              dispatch({
                                type: 'SET_UNUSUAL_ACTIVITY_DATA',
                                payload: {
                                  referenceId: record.reference_id,
                                  selectedReferee: referee
                                }
                              });
                            }}
                          >
                            Unusual Activity
                          </Stack>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}
