var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import sharedStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import usestyles from "../useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
import ErrorIcon from "@material-ui/icons/Error";
import TextEditor from "../../../../../NewUI/Components/CustomUIElements/TextEditor";
const MAX_KEY_SELLING_POINTS = 3;
const MAX_CHAR_LIMIT = 80;
function Step2({ answers, setAnswers, jobAdParams, requiredRefs, activeError }) {
  var _a, _b;
  const sharedClasses = sharedStyles();
  const classes = usestyles();
  return /* @__PURE__ */ React.createElement("div", { className: sharedClasses.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Feature Job on Viewjobs"), /* @__PURE__ */ React.createElement(Box, { className: classes.switchContainer }, /* @__PURE__ */ React.createElement(FormLabel, null, "From $350 + GST, boost visibility and attract more candidates."), /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      control: /* @__PURE__ */ React.createElement(
        Switch,
        {
          checked: answers.featured,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { featured: !answers.featured })),
          color: "secondary"
        }
      ),
      label: ""
    }
  )), answers.featured && /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: false, className: sharedClasses.label }, "Short Summary (Optional)"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        className: sharedClasses.textField,
        variant: "outlined",
        value: answers.jobPreview.summary,
        onChange: (e) => {
          const newValue = e.target.value;
          if (newValue.length <= 150 && newValue !== answers.jobPreview.summary) {
            setAnswers(__spreadProps(__spreadValues({}, answers), {
              jobPreview: __spreadProps(__spreadValues({}, answers.jobPreview), { summary: newValue })
            }));
          }
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: sharedClasses.helperText }, ((_b = (_a = answers.jobPreview) == null ? void 0 : _a.summary) == null ? void 0 : _b.length) || 0, "/150")
  ), answers.featured && /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: false, className: sharedClasses.label }, "Key Selling Points (Optional)"),
    /* @__PURE__ */ React.createElement("span", { className: sharedClasses.subLabel }, "Enter ", MAX_KEY_SELLING_POINTS, " key selling points to attract candidates to view your role"),
    Array.from({ length: MAX_KEY_SELLING_POINTS }).map((_, index) => {
      var _a2, _b2;
      return /* @__PURE__ */ React.createElement(
        FormControl,
        {
          key: index,
          fullWidth: true,
          className: `${sharedClasses.formControl} ${sharedClasses.medium}`
        },
        /* @__PURE__ */ React.createElement(
          TextField,
          {
            className: sharedClasses.textField,
            variant: "outlined",
            value: answers.jobPreview[`key${index + 1}`],
            onChange: (e) => {
              const newValue = e.target.value;
              if (newValue.length <= MAX_CHAR_LIMIT && newValue !== answers.jobPreview[`key${index + 1}`]) {
                setAnswers(__spreadProps(__spreadValues({}, answers), {
                  jobPreview: __spreadProps(__spreadValues({}, answers.jobPreview), { [`key${index + 1}`]: newValue })
                }));
              }
            }
          }
        ),
        /* @__PURE__ */ React.createElement(FormHelperText, { className: sharedClasses.helperText }, ((_b2 = (_a2 = answers.jobPreview) == null ? void 0 : _a2[`key${index + 1}`]) == null ? void 0 : _b2.length) || 0, "/", MAX_CHAR_LIMIT)
      );
    })
  ), /* @__PURE__ */ React.createElement(
    Box,
    {
      ref: requiredRefs.description,
      className: `${sharedClasses.label} ${classes.descriptionLabel}`
    },
    "Job description",
    /* @__PURE__ */ React.createElement("span", null, "*"),
    activeError === requiredRefs.description && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.large}`,
      style: { marginTop: 5 }
    },
    /* @__PURE__ */ React.createElement(
      TextEditor,
      {
        editorState: answers.description,
        setAnswers,
        answers,
        activeError,
        requiredRef: requiredRefs.description,
        classes: sharedClasses
      }
    )
  ));
}
Step2.propTypes = jobAdPropTypes;
export default Step2;
