"use strict";
export const styles = {
  candidateHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 2
  },
  candidateNameHeader: {
    fontSize: "35px",
    fontWeight: "bold",
    color: "#666666"
  },
  candidateActionIconsContainer: {
    flexDirection: "row",
    color: "#666666",
    columnGap: 3,
    "& svg": {
      cursor: "pointer"
    }
  },
  candidateTabs: {
    "& .MuiTabs-flexContainer": { columnGap: 2 },
    "& button": {
      fontFamily: "Source Sans Pro",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "capitalize",
      color: "#939393",
      minWidth: "unset",
      padding: "12px 8px",
      "&:focus": { outline: "none" }
    },
    "& button.Mui-selected": { color: "#084D6D", fontWeight: 600 }
  },
  candidateTabIndicator: {
    backgroundColor: "#5BC4C0",
    marginLeft: "0.5em"
  },
  candidateProfileDetails: {
    flexDirection: "row",
    columnGap: 2,
    alignItems: "center"
  },
  candidateProfileAddress: {
    flexDirection: "row",
    columnGap: 2,
    alignItems: "start",
    width: "75%"
  },
  candidateApplicationsTableLink: {
    "& a": {
      color: "#000000"
    }
  },
  candidateApplicationsTableStatusContainer: {
    border: "1px solid #E3E3E3",
    borderRadius: "6px",
    flexDirection: "row",
    columnGap: 1,
    padding: 1,
    alignItems: "center"
  },
  High: {
    background: "rgba(227, 125, 122, 0.12)",
    color: "#E37D7A"
  },
  Normal: {
    background: "rgba(230, 160, 82, 0.12)",
    color: "#E6A052"
  },
  Low: {
    background: "rgba(140, 207, 149, 0.12)",
    color: "#8CCF95"
  },
  candidateTaskChip: {
    padding: "0px 8px",
    fontWeight: 600,
    fontSize: "12px",
    height: "25px",
    maxWidth: "220px"
  },
  modalContainer: {
    maxHeight: "unset",
    width: "800px",
    padding: "40px",
    height: "auto"
  },
  modalContent: {
    width: "100%",
    height: "100%",
    rowGap: 3,
    overflow: "auto"
  },
  modalTitle: {
    fontWeight: "bold",
    color: "#084D6D",
    fontSize: "25px"
  },
  modalCandidateName: {
    color: "#666666",
    fontWeight: "bold"
  },
  prioritySelectStyling: {
    width: "250px",
    "& .MuiInputBase-input": {
      margin: "unset",
      marginTop: "1px",
      padding: "7px 16px"
    }
  },
  listboxProps: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#333333",
    maxHeight: "200px"
  },
  autoCompleteTextfield: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#E37D7A",
      margin: "0px 3px",
      fontFamily: "inherit"
    }
  },
  showNoteContainer: {
    padding: 3,
    borderRadius: "6px",
    boxShadow: "0px 0px 30px -18px #0000004D inset",
    rowGap: 1
  },
  htmlEditor: {
    "& .tox-editor-header, .tox-statusbar": { display: "none !important" },
    "& .tox-tinymce": { border: "unset" },
    "& .mce-content-body": { fontFamily: "Source Sans Pro" }
  },
  candidateSectionContainer: {
    padding: 3,
    border: "1px solid #E3E3E3",
    borderRadius: "6px"
  },
  candidateSectionHeader: { fontSize: "16px", fontWeight: 600, color: "#333333" },
  candidateApplicationsRating: {
    fontWeight: 600,
    borderRadius: "3px",
    alignItems: "center",
    justifyContent: "center",
    height: "25px"
  },
  candidateNameContainer: {
    flexDirection: "row",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#084D6D",
    columnGap: 1
  },
  linkedInButton: {
    padding: "3px",
    minWidth: "unset",
    border: "1px solid #E3E3E3",
    borderRadius: "6px",
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      outline: "none",
      borderColor: "#E3E3E3",
      background: "transparent"
    }
  },
  timestamp: {
    minWidth: "60px",
    alignItems: "end",
    color: "#939393",
    fontWeight: 600
  },
  emailStatus: {
    fontWeight: "bold",
    borderRadius: "6px",
    textTransform: "uppercase",
    width: "min-content",
    padding: "4px 20px",
    margin: "8px 0px"
  },
  listStyle: {
    maxHeight: "144px",
    overflow: "auto"
  }
};
